import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

import api from '../../../services/api';

import { Container, Content } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useAuth } from '../../../hooks/auth';

interface UserParams {
  id: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  password: string;
  is_admin: boolean;
}

const Edit: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);

  const { params } = useRouteMatch<UserParams>();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const { user: authUser, updateUser } = useAuth();

  useEffect(() => {
    api.get(`/users/${params.id}`).then(response => {
      setUser(response.data);
    });
  }, [params.id]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          password: Yup.string(),
          is_admin: Yup.bool()
            .required('Função obrigatória')
            .typeError('Função obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, email, is_admin, password } = data;

        const formData = {
          name,
          email,
          is_admin,
          ...(password ? { password } : {}),
        };

        const response = await api.put(`/users/${params.id}`, formData);
        setUser(response.data);

        if (authUser.id === params.id) {
          updateUser(response.data);
        }

        window.scrollTo(0, 0);

        addToast({
          type: 'success',
          title: 'Usuário atualizado',
          description: 'O usuário foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao editar usuário',
          description:
            'Ocorreu um erro ao atualizar o usuário, por favor, cheque as informações',
        });
      }
    },
    [addToast, authUser, updateUser, params.id],
  );

  const isAdmin = [
    {
      value: false,
      label: 'Usuário',
    },
    {
      value: true,
      label: 'Administrador',
    },
  ];

  return (
    <>
      <Header />
      <PageHeader title="Editar usuário" />
      <Container>
        {user && (
          <Content>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                name: user.name,
                email: user.email,
                is_admin: user?.is_admin,
              }}
            >
              <fieldset>
                <legend>Dados do usuário</legend>
                <hr />

                <div className="form-row">
                  <Input name="name" placeholder="Nome completo" />
                  <Input type="email" name="email" placeholder="E-mail" />
                </div>
                <div className="form-row">
                  <Input type="password" name="password" placeholder="Senha" />
                  <Select
                    options={isAdmin}
                    name="is_admin"
                    placeholder="Função"
                    defaultValue={{
                      value: user.is_admin,
                      label: user.is_admin ? 'Administrador' : 'Usuário',
                    }}
                  />
                </div>
              </fieldset>
              <footer>
                <button type="submit" title="Enviar">
                  Enviar
                </button>
              </footer>
            </Form>
          </Content>
        )}
      </Container>
    </>
  );
};

export default Edit;
