import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';

import { Container, Content } from './styles';

import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import DayPicker from '../../components/DayPicker';

import api from '../../services/api';
import { attorneys, counselors } from '../../data/data';
import getValidationErrors from '../../utils/getValidationErrors';

interface Category {
  id: string;
  title: string;
}

interface Agency {
  id: string;
  title: string;
}

const NewOpinion: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [agencies, setAgencies] = useState<Agency[]>([]);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  // Get Categories and Agencies
  useEffect(() => {
    api.get(`/categories`).then(response => {
      const categoriesFormatted = response.data.map((category: Category) => {
        return {
          value: category.id,
          label: category.title,
        };
      });
      setCategories(categoriesFormatted);
    });

    api.get(`/agencies`).then(response => {
      const agenciesFormatted = response.data.map((agency: Agency) => {
        return {
          value: agency.id,
          label: agency.title,
        };
      });
      setAgencies(agenciesFormatted);
    });
  }, []);

  // Submit Form
  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          opinion_number: Yup.string().required(
            'Número do parecer obrigatório',
          ),
          opinion_year: Yup.number().required('Ano do parecer obrigatório'),
          opinion_date: Yup.date()
            .required('Data do parecer obrigatória')
            .typeError('Data do parecer obrigatória'),
          lawsuit_number: Yup.string().required(
            'Número do processo obrigatório',
          ),
          lawsuit_year: Yup.number().required('Ano do processo obrigatório'),
          lawsuit_date: Yup.date()
            .required('Data do processo obrigatória')
            .typeError('Data do processo obrigatória'),
          interested: Yup.string().required('Nome do interessado obrigatório'),
          subject: Yup.string().required('Assunto obrigatório'),
          category_id: Yup.string().required('Categoria obrigatória'),
          agency_id: Yup.string().required(
            'Unidade Jurisdicionada obrigatória',
          ),
          counselor: Yup.string().required('Conselheiro obrigatório'),
          attorney: Yup.string().required('Procurador de Contas obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/opinions', data);
        history.push(`/detalhes/${response.data.id}`);
        addToast({
          type: 'success',
          title: 'Parecer criado',
          description: 'Parecer foi criado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na criação',
          description:
            'Ocorreu um erro ao criar o parecer, por favor, cheque as informações',
        });
      }
    },
    [addToast, history],
  );

  return (
    <div>
      <Header />
      <PageHeader title="Novo parecer" />
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <fieldset>
              <legend>Dados do parecer</legend>
              <hr />
              <div className="form-row">
                <Input name="opinion_number" placeholder="Número do parecer" />
                <Input name="opinion_year" placeholder="Ano do parecer" />
                <DayPicker name="opinion_date" placeholder="Data do parecer" />
              </div>
              <div className="form-row">
                <Input name="lawsuit_number" placeholder="Número do processo" />
                <Input name="lawsuit_year" placeholder="Ano do processo" />
                <DayPicker name="lawsuit_date" placeholder="Data do processo" />
              </div>
              <div className="form-row">
                <Input name="interested" placeholder="Interessado" />
                <Input name="subject" placeholder="Assunto" />
              </div>
              <div className="form-row">
                <Select
                  options={categories}
                  name="category_id"
                  placeholder="Categoria"
                />
                <Select
                  options={agencies}
                  name="agency_id"
                  placeholder="Unidade Jurisdicionada"
                />
              </div>
              <div className="form-row">
                <Select
                  options={counselors}
                  name="counselor"
                  placeholder="Conselheiro Relator"
                />
                <Select
                  options={attorneys}
                  name="attorney"
                  placeholder="Procurador de Contas"
                />
              </div>
              <div className="form-row">
                <TextArea name="description" placeholder="Descrição" />
              </div>
            </fieldset>
            <footer>
              <button type="submit" title="Enviar">
                Enviar
              </button>
            </footer>
          </Form>
        </Content>
      </Container>
    </div>
  );
};

export default NewOpinion;
