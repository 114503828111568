import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Search from '../pages/Search';
import Details from '../pages/Details';
import Favorites from '../pages/Favorites';
import Page404 from '../pages/Page404';

// Opinions
import NewOpinion from '../pages/NewOpinion';
import EditOpinion from '../pages/EditOpinion';
import Profile from '../pages/Profile';
import ListOpinions from '../pages/ListOpinions';

// Users
import ListUsers from '../pages/Users/List';
import EditUser from '../pages/Users/Edit';
import NewUser from '../pages/Users/New';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/dashboard" exact component={Dashboard} isPrivate />
    <Route path="/search" exact component={Search} isPrivate />
    <Route path="/detalhes/:id" exact component={Details} isPrivate />
    <Route path="/favoritos" exact component={Favorites} isPrivate />
    <Route path="/me" exact component={Profile} isPrivate />

    {/* Admin routes */}
    <Route path="/pareceres" exact component={ListOpinions} isPrivate admin />
    <Route
      path="/pareceres/novo"
      exact
      component={NewOpinion}
      isPrivate
      admin
    />
    <Route
      path="/pareceres/:id/editar"
      exact
      component={EditOpinion}
      isPrivate
      admin
    />

    {/* Users */}
    <Route path="/usuarios" exact component={ListUsers} isPrivate admin />
    <Route path="/usuarios/novo" exact component={NewUser} isPrivate admin />
    <Route
      path="/usuarios/:id/editar"
      exact
      component={EditUser}
      isPrivate
      admin
    />

    <Route component={Page404} isPrivate />
  </Switch>
);

export default Routes;
