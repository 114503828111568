export const years = [
  {
    value: '2017',
    label: '2017',
  },
  {
    value: '2018',
    label: '2018',
  },
  {
    value: '2019',
    label: '2019',
  },
  {
    value: '2020',
    label: '2020',
  },
];
export const counselors = [
  {
    value: 'José Carlos Novelli',
    label: 'José Carlos Novelli',
  },
  {
    value: 'Jaqueline Jacobsen Marques',
    label: 'Jaqueline Jacobsen Marques',
  },
  {
    value: 'Domingos Neto',
    label: 'Domingos Neto',
  },
  {
    value: 'Antonio Joaquim',
    label: 'Antonio Joaquim',
  },
  {
    value: 'Valter Albano',
    label: 'Valter Albano',
  },
  {
    value: 'Moises Maciel',
    label: 'Moises Maciel',
  },
  {
    value: 'Sérgio Ricardo',
    label: 'Sérgio Ricardo',
  },
  {
    value: 'Luiz Carlos Pereira',
    label: 'Luiz Carlos Pereira',
  },
  {
    value: 'Alencar Soares',
    label: 'Alencar Soares',
  },
  {
    value: 'Luiz Henrique Lima',
    label: 'Luiz Henrique Lima',
  },
  {
    value: 'João Batista Camargo',
    label: 'João Batista Camargo',
  },
  {
    value: 'Isaías Lopes da Cunha',
    label: 'Isaías Lopes da Cunha',
  },
  {
    value: 'Guilherme Antonio Maluf',
    label: 'Guilherme Antonio Maluf',
  },
  {
    value: 'Ronaldo Ribeiro',
    label: 'Ronaldo Ribeiro',
  },
];

export const attorneys = [
  {
    value: 'Alisson Carvalho de Alencar',
    label: 'Alisson Carvalho de Alencar',
  },
  {
    value: 'William de Almeida Brito Júnior',
    label: 'William de Almeida Brito Júnior',
  },
  {
    value: 'Gustavo Coelho Deschamps',
    label: 'Gustavo Coelho Deschamps',
  },
  {
    value: 'Getúlio Velasco Moreira Filho',
    label: 'Getúlio Velasco Moreira Filho',
  },
];
