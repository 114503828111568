/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker/types/Props';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

import YearMonthForm from './YearMonthForm';

import 'react-day-picker/lib/style.css';

interface DayPickerDataProps extends DayPickerInputProps {
  name: string;
}

const currentYear = new Date().getFullYear();
const fromMonth = new Date(2016, 0);
const toMonth = new Date(currentYear, 11);

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

const WEEKDAYS_SHORT = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

const DayPicker: React.FC<DayPickerDataProps> = ({ name, ...rest }) => {
  const daypickerRef = useRef<DayPickerInput>(null);

  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(defaultValue || Date);
  const [month, setMonth] = useState(fromMonth);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleYearMonthChange = useCallback(month => {
    setMonth(month);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: daypickerRef.current,
      path: 'state.value',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error} isFocused={isFocused}>
      <DayPickerInput
        ref={daypickerRef}
        onDayPickerShow={handleInputFocus}
        onDayPickerHide={handleInputBlur}
        dayPickerProps={{
          month,
          showWeekNumbers: true,
          todayButton: 'Hoje',
          selectedDays: [date],
          onDayClick: () => setDate(date),
          locale: 'pt-br',
          months: MONTHS,
          weekdaysLong: WEEKDAYS_LONG,
          weekdaysShort: WEEKDAYS_SHORT,
          firstDayOfWeek: 0,
          fromMonth,
          toMonth,
          captionElement: ({ date }) => (
            <YearMonthForm
              date={date}
              onChange={handleYearMonthChange}
              fromMonth={fromMonth}
              toMonth={toMonth}
              months={MONTHS}
            />
          ),
        }}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="var(--color-error)" size="2rem" />
        </Error>
      )}
    </Container>
  );
};

export default DayPicker;
