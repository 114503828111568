import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

import 'react-day-picker/lib/style.css';

interface ContainerProps {
  isFocused: boolean;
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background: var(--color-gray-light);
  padding: 1.6rem;
  height: 5.6rem;
  border-radius: 1rem;

  ${props =>
    props.hasError &&
    css`
      border: 2px solid;
      border-color: var(--color-primary-light);
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid;
      border-color: var(--color-secondary);
    `}

  .DayPickerInput {
    border: none;
    background: transparent;

    input {
      display: flex;
      flex: 1;
      align-items: center;
      background: transparent;
      border: 0;
    }
  }

  .DayPickerInput .DayPickerInput-OverlayWrapper .DayPickerInput-Overlay {
    margin-top: 0.5rem;
  }

  .DayPickerInput
    .DayPickerInput-OverlayWrapper
    .DayPickerInput-Overlay
    .DayPicker {
    font-size: 1.5rem !important;

    & .DayPicker-wrapper > .DayPicker-Footer > .DayPicker-TodayButton {
      width: 100%;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-left: 1.6rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--color-error);
    color: var(--color-error-text);

    &::before {
      border-color: var(--color-error) transparent;
    }
  }
`;
