import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

import api from '../../../services/api';

import { Container, Content } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';

const New: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          password: Yup.string()
            .required('Senha obrigatória')
            .min(8, 'No mínimo 8 caracteres'),
          is_admin: Yup.bool()
            .required('Função obrigatória')
            .typeError('Função obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/usuarios');
        addToast({
          type: 'success',
          title: 'Usuário criado',
          description: 'O usuário foi criado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na criação',
          description:
            'Ocorreu um erro ao criar o usuário, por favor, cheque as informações',
        });
      }
    },
    [addToast, history],
  );

  const isAdmin = [
    {
      value: false,
      label: 'Usuário',
      selected: 'selected',
    },
    {
      value: true,
      label: 'Administrador',
    },
  ];

  return (
    <>
      <Header />
      <PageHeader title="Novo usuário" />
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <fieldset>
              <legend>Dados do usuário</legend>
              <hr />

              <div className="form-row">
                <Input name="name" placeholder="Nome completo" />
                <Input type="email" name="email" placeholder="E-mail" />
              </div>
              <div className="form-row">
                <Input type="password" name="password" placeholder="Senha" />
                <Select
                  options={isAdmin}
                  name="is_admin"
                  placeholder="Função"
                  defaultValue={isAdmin[0]}
                />
              </div>
            </fieldset>
            <footer>
              <button type="submit" title="Enviar">
                Enviar
              </button>
            </footer>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default New;
