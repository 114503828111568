import React from 'react';
import { Container } from './styles';

interface PageHeaderProps {
  title?: string;
  description?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description }) => {
  return (
    <Container hasTitle={!!title}>
      {title && (
        <div className="header-content">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      )}
    </Container>
  );
};

export default PageHeader;
