import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  admin?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  admin = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  if (admin && user) {
    return (
      <ReactDOMRoute
        {...rest}
        render={({ location }) => {
          return isPrivate && admin === !!user.is_admin ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: admin ? '/' : '/dashboard',
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
