import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';

import { Container } from './styles';

const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const scrollToTop = useCallback(() => {
    const scrollTop = document.querySelector('.scroll-top');

    scrollTop?.addEventListener(
      'click',
      () => {
        scrollTop.classList.remove('animated');
        scrollTop.classList.add('animated');
        navigator.vibrate(100);
      },
      false,
    );
    scrollTop?.classList.add('animated');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, [toggleVisibility]);

  return (
    <>
      {isVisible && (
        <Container
          className="scroll-top"
          type="button"
          onClick={scrollToTop}
          title="Voltar ao topo"
        >
          <FiChevronUp size={28} />
        </Container>
      )}
    </>
  );
};

export default ScrollToTop;
