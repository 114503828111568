import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--color-gray-light);
  border-radius: 1rem;
  height: 5.6rem;
  width: 100%;
  color: var(--color-text-in-gray);
  padding: 1.6rem;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 1.6rem;
  }

  ${props =>
    props.hasError &&
    css`
      border: 2px solid;
      border-color: var(--color-primary-light);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--color-secondary);
      border: 2px solid;
      border-color: var(--color-secondary);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--color-secondary);
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;

    &:placeholder {
      color: var(--color-text-in-gray);
    }
  }

  svg {
    margin-right: 1.6rem;
  }
`;

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-left: 1.6rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--color-error);
    color: var(--color-error-text);

    &::before {
      border-color: var(--color-error) transparent;
    }
  }
`;
