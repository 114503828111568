import styled from 'styled-components';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) and (max-width: 1120px) {
    padding: 3rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 3rem;
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 0.5rem;
  font-family: Poppins;
  font-weight: 600;
  color: var(--color-dark);
  background: var(--color-light);
  border-radius: 1rem;
  padding: 5rem;
  -webkit-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);

  svg {
    color: #cacaca;
  }
`;
