import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 5rem;
    color: var(--color-title-dark);
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 100%;

    padding: 2rem;
  }

  @media (max-width: 768px) {
    width: 100vw;

    padding: 2rem;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-secondary);
    height: 4.8rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.6rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#4361EE')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    height: 6rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#CE1D37')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    justify-content: center;
    flex-direction: column;

    a,
    button {
      width: 100%;
    }
  }
`;

export const OpinionsList = styled.div`
  width: 100%;

  strong {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    color: var(--color-title-dark);
  }

  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;

    list-style: none;
    margin: 5rem 0;

    .active {
      background: #d12d45;
      color: #fff;
    }
    li {
      margin: 0 1.5rem;
      background: #fff;
      padding: 1.2rem 1.5rem;
      border-radius: 0.4rem;
      box-shadow: 0.2rem 0.2rem 1.6rem 0.2rem rgba(0, 0, 0, 0.2);

      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background: #d12d45;
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    .pagination {
      li {
        display: none;
      }

      li.active,
      li.previous,
      li.next {
        display: flex;
      }
    }
  }
`;
