import React, { useCallback, useEffect, useState } from 'react';
import { MdFavorite } from 'react-icons/md';
import { IoMdHeartDislike } from 'react-icons/io';

import { Container } from './styles';
import { useFavorites } from '../../hooks/favorites';

interface FavoriteButtonProps {
  opinion_id: string;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ opinion_id }) => {
  const { checkIsFavorite, toggleFavorite } = useFavorites();
  const [isFavorite, setIsFavorite] = useState<boolean>(
    checkIsFavorite(opinion_id),
  );

  useEffect(() => {
    setIsFavorite(checkIsFavorite(opinion_id));
  }, [checkIsFavorite, opinion_id]);

  const handleToggleFavorite = useCallback(
    (id: string) => {
      toggleFavorite(id);
    },
    [toggleFavorite],
  );

  return (
    <Container
      type="button"
      title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'}
      onClick={() => handleToggleFavorite(opinion_id)}
    >
      {isFavorite ? (
        <IoMdHeartDislike size="3rem" />
      ) : (
        <MdFavorite size="3rem" />
      )}
    </Container>
  );
};

export default FavoriteButton;
