import styled, { css } from 'styled-components';

interface FiltersProps {
  active?: boolean;
}

export const Container = styled.div`
  margin-top: 4rem;
`;

export const Results = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;

  @media (max-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const OpinionsList = styled.div`
  @media (max-width: 1023px) {
    order: 2;
  }
`;

export const Filters = styled.div<FiltersProps>`
  -webkit-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);

  border-radius: 1rem;
  background: var(--color-light);

  padding: 2rem;

  .filter-header {
    > svg {
      display: none;
    }

    #toggle-filters {
      display: none;
    }
  }

  .filter-title {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: var(--color-primary);
    margin-bottom: 2rem;
  }

  .clear-filters {
    margin-top: 1.5rem;
    font-size: 1.6rem;
    text-decoration: underline;
    color: var(--color-primary-light);
    transition: color 0.5s;

    &:hover {
      color: var(--color-dark);
    }
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;

    fieldset {
      border: 0;

      & + fieldset {
        margin-top: 2rem;
      }

      legend {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.8rem;
        color: var(--color-dark);
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    flex-direction: column;

    order: 1;

    .filter-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      #toggle-filters {
        display: flex;
      }

      span {
        font-size: 1.6rem;
      }

      > svg {
        display: flex;
        cursor: pointer;
        pointer-events: none;
      }
    }

    .filter-title {
      margin: 0;

      svg {
        margin-right: 1rem;
      }
    }

    #toggle-filters {
      z-index: 1;
      cursor: pointer !important;
    }

    form {
      ${props =>
        props.active
          ? css`
              display: flex;
            `
          : css`
              display: none;
            `}

      margin-top: 1.6rem;
    }
  }
`;
