import styled, { css } from 'styled-components';

import { OptionTypeBase } from 'react-select';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 1rem;
  background: var(--color-gray-light);
  .react-select__control {
    cursor: pointer;
  }

  ${props =>
    props.hasError &&
    css`
      border: 2px solid;
      border-color: var(--color-primary-light);
      padding-right: 1rem;
    `}
`;

export const selectStyles = {
  container: (base: OptionTypeBase): any => ({
    ...base,
    flex: 1,
  }),
  control: (): any => ({
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '1rem',
    height: '5.6rem',
  }),
  valueContainer: (base: OptionTypeBase): any => ({
    ...base,
    display: 'inline-block !important',
    overflowX: 'hidden !important',
    maxWidth: '1200px !important',
  }),
  option: (base: OptionTypeBase, { isDisabled }: OptionTypeBase): any => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    height: '5rem',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
};

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-left: 1rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--color-error);
    color: var(--color-error-text);

    &::before {
      border-color: var(--color-error) transparent;
    }
  }
`;
