import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --color-background: #F9F9F9;
    --color-primary: #CE1D37;
    --color-primary-light: #D12D45;
    --color-secondary: #4361EE;
    --color-light: #ffffff;
    --color-dark: #170F44;
    --color-gray: #CFCDCD;
    --color-gray-light: #F9F9FA;
    --color-text-base: #434141;
    --color-text-light: #ffffff;
    --color-text-in-gray: #9C98A6;
    --color-title-dark: #170F44;
    --color-title-light: #170F44;
    --color-error: #c53030;
    --color-error-text: #fff;
    --color-success: #12a454;
    --gradient-red: linear-gradient(90deg, #CB2D3E 0%, #EF473A 100%);

    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html, body, #root {
    height: 100vh;
  }

  body {
    color: var(--color-text-base);
    background: var(--color-background);
    -webkit-font-smoothing: antialiased;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  body, input, textarea {
    font: 500 1.6rem 'Roboto Regular', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, button {
    font: 600 2rem 'Poppins', sans-serif;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: 0;
  }

  @media (min-width: 768px) {
    :root {
      font-size: 62,5%
    }
  }

  .container {
    width: 100%;
    max-width: 112rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
  }

  @media (min-width: 769px) and (max-width: 1120px) {
    .container {
      padding: 3rem;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: 3rem;
      width: 100%;
    }
  }
`;
