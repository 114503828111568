import React from 'react';
import { Link } from 'react-router-dom';
import FavoriteButton from '../FavoriteButton';

import { Container } from './styles';

interface OpinionData {
  id: string;
  title: string;
  description: string;
}

interface OpinionProps {
  opinion: OpinionData;
}

const Opinion: React.FC<OpinionProps> = ({ opinion }) => {
  const { id, title, description } = opinion;

  return (
    <Container key="id">
      <span>{title}</span>
      <p>{description}</p>

      <footer>
        <Link to={`/detalhes/${id}`} title="Ver detalhes do parecer">
          Detalhes
        </Link>

        <FavoriteButton opinion_id={id} />
      </footer>
    </Container>
  );
};

export default Opinion;
