import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-light);
  width: 100vw;
  height: 10rem;
  padding: 3rem;

  @media (max-width: 768px) {
    height: 8rem;
    position: relative;
  }
`;

export const Content = styled.div`
  max-width: 112rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  > a > img {
    height: 8rem;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    > a > img {
      height: 6rem;
    }
  }
`;

export const Navbar = styled.div`
  .menu-toggle {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  .nav-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 2.4rem;

    > a,
    span {
      display: flex;
      align-items: right;
      float: right;
      font-family: Poppins;
      font-weight: 600;
      color: var(--color-dark);
      text-decoration: none;
      margin-left: 2rem;
      cursor: pointer;

      svg {
        margin-right: 0.5rem;
      }
    }

    button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      font-weight: 600;
      font-size: 1.6rem;

      svg {
        margin-right: 0.5rem;
      }
    }

    .dropdown {
      position: relative;
      display: flex;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      align-items: center;
      flex-direction: column;
      background-color: var(--color-light);
      min-width: 20rem;
      box-shadow: 0rem 0.8rem 1.6rem 0rem rgba(0, 0, 0, 0.2);
      z-index: 1;
      margin-top: 2.5rem;
      border-radius: 1rem;

      a,
      button {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1.2rem 1.6rem;
        font-family: Poppins;
        font-weight: 400;
        font-size: 1.4rem;
        color: var(--color-dark);
        text-decoration: none;
      }

      a:hover,
      button:hover {
        background: var(--color-gray-light);

        &:first-child {
          border-radius: 1rem 1rem 0 0;
        }
        &:last-child {
          border-radius: 0 0 1rem 1rem;
        }
      }

      svg {
        margin-right: 1rem;
      }
    }

    .dropdown:hover .dropdown-content {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    .nav-menu {
      display: none;
    }

    .menu-toggle {
      display: flex;

      > svg {
        z-index: 10;
        cursor: pointer;
      }
    }

    .mobile-menu {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 100%;
      left: 0;
      background: #fff;
      box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
      width: 100%;

      > a,
      button {
        display: inherit;
        justify-content: center;
        padding: 2rem;
        width: 100%;
        margin: 0;

        align-items: right;
        float: right;
        font-family: Poppins;
        font-weight: 600;
        color: var(--color-dark);
        text-decoration: none;
        font-size: 1.6rem;
        cursor: pointer;

        svg {
          margin-right: 1rem;
        }

        &:hover {
          background: var(--color-primary-light);
          color: var(--color-light);
          transition: all 0.2s ease-in;
        }
      }

      > span {
        display: inherit;
        justify-content: center;
        padding: 1rem;
        width: 100%;
        margin: 0;
        background: var(--color-primary-light);
        color: var(--color-light);
        font-weight: bold;
        cursor: pointer;
      }

      .dropdown {
        display: none;
      }
    }
  }
`;
