import React, { useCallback, ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

import { Container, UploadMessage } from './styles';

interface DropzoneProps {
  onUpload: (files: File[]) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onUpload }: DropzoneProps) => {
  const onDrop = useCallback(
    acceptedFiles => {
      onUpload(acceptedFiles);
    },
    [onUpload],
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({ onDrop, accept: '.pdf, application/pdf' });

  const renderDragMessage = useCallback(
    (dragActive: boolean, dragReject: boolean): ReactNode => {
      if (!dragActive) {
        return (
          <UploadMessage>Selecione ou arraste o arquivo aqui</UploadMessage>
        );
      }

      if (dragReject) {
        return (
          <UploadMessage type="error">Arquivo não suportado</UploadMessage>
        );
      }

      return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
    },
    [],
  );

  return (
    <Container
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <input {...getInputProps()} />
      {renderDragMessage(isDragActive, isDragReject)}
    </Container>
  );
};

export default Dropzone;
