import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 5rem;
    color: var(--color-title-dark);
  }

  @media (min-width: 769px) and (max-width: 1120px) {
    padding: 3rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 2rem 2rem 2rem;
    width: 100vw;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-secondary);
    height: 4.8rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.6rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#4361EE')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    height: 6rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#CE1D37')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  @media (max-width: 768px) {
    a {
      width: 100%;
    }
  }
`;

export const UsersList = styled.div`
  width: 100%;

  .desktop-only {
    display: flex;
  }

  table {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0.2rem 0.2rem 1.6rem 0.2rem rgba(0, 0, 0, 0.2);
    border: 1px transparent;
    border-spacing: 0ch;
    border-collapse: separate !important;
    border-radius: 0.8rem;

    thead {
      flex-direction: row;
      background: var(--color-gray-light);
      font-size: 1.2rem;
      text-transform: uppercase;
      tr {
        display: flex;
        width: 100%;
        height: 5rem;
        flex-grow: 1;

        th {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-bottom: 1px solid #ededed;
        }
      }
    }

    tbody {
      background: #fff;
      border-color: #fff;
      display: flex;
      flex-direction: column;
      width: 100%;

      tr {
        width: 100%;
        display: flex;
        flex-grow: 1;

        td {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          text-align: center;
          border: 0;
          border-bottom: 1px solid #ededed;
          padding: 2rem 0;

          svg {
            margin-right: 1rem;
            transition: color 0.2s;
            color: #9c98a6;

            &:hover {
              color: var(--color-primary-light);
            }
          }
        }
      }
    }
  }

  strong {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    color: var(--color-title-dark);
  }

  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;

    list-style: none;
    margin: 5rem 0;

    .active {
      background: #d12d45;
      color: #fff;
    }
    li {
      margin: 0 1.5rem;
      background: #fff;
      padding: 1.2rem 1.5rem;
      border-radius: 0.4rem;
      box-shadow: 0.2rem 0.2rem 1.6rem 0.2rem rgba(0, 0, 0, 0.2);

      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background: #d12d45;
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }

    .pagination {
      li {
        display: none;
      }

      li.active,
      li.previous,
      li.next {
        display: flex;
      }
    }
  }
`;
