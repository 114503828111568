import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FiChevronDown, FiFilter } from 'react-icons/fi';
import useQuery from '../../hooks/query';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import Header from '../../components/Header';

import { Container, Results, OpinionsList, Filters } from './styles';
import PageHeader from '../../components/PageHeader';
import Opinion from '../../components/Opinion';
import Select from '../../components/Select';

import { years, counselors, attorneys } from '../../data/data';
import Button from '../../components/Button';

interface OpinionData {
  id: string;
  title: string;
  description: string;
  opinion_date: string;
  counselor: string;
  attorney: string;
  category_id: string;
  agency_id: string;
}

interface CategoryData {
  value: string;
  label: string;
}

interface Category {
  id: string;
  title: string;
}

interface AgencyData {
  value: string;
  label: string;
}

interface Agency {
  id: string;
  title: string;
}

interface FilterFormData {
  year?: string;
  counselor?: string;
  attorney?: string;
  category?: string;
  agency?: string;
}

const Search: React.FC = () => {
  const query = useQuery();
  const { addToast } = useToast();

  const [opinions, setOpinions] = useState<OpinionData[]>([]);
  const [initialOpinions, setInitialOpinions] = useState<OpinionData[]>([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [activeFilters, setActiveFilters] = useState(false);
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [agencies, setAgencies] = useState<AgencyData[]>([]);

  const toggleFilters = useCallback(() => {
    setActiveFilters(!activeFilters);
  }, [activeFilters]);

  const terms = query.get('terms');

  const filterFormRef = useRef<FormHandles>(null);

  // apply filters to opinions
  const filterOpinions = useCallback(
    (data: FilterFormData) => {
      const filteredOpinions = initialOpinions.filter(opinion => {
        const year = new Date(opinion.opinion_date).getFullYear();

        return (
          year === Number(data.year) ||
          opinion.counselor === data.counselor ||
          opinion.attorney === data.attorney ||
          opinion.category_id === data.category ||
          opinion.agency_id === data.agency
        );
      });

      setOpinions(filteredOpinions);
    },
    [initialOpinions],
  );

  // Fetch categories and agencies from api
  useEffect(() => {
    api.get('/categories').then(response => {
      const categoriesData = response.data.map((category: Category) => {
        return {
          value: category.id,
          label: category.title,
        };
      });
      setCategories(categoriesData);
    });

    api.get('/agencies').then(response => {
      const agenciesData = response.data.map((agency: Agency) => {
        return {
          value: agency.id,
          label: agency.title,
        };
      });
      setAgencies(agenciesData);
    });
  }, []);

  // Fetch search from api using query params on page load
  useEffect(() => {
    try {
      api
        .get('/search', {
          params: {
            terms,
          },
        })
        .then(response => {
          setOpinions(response.data);
          setInitialOpinions(response.data);
          setFiltersVisible(true);
        });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na busca',
        description:
          'Ocorreu um erro ao fazer a busca. Tente novamente mais tarde.',
      });
    }
  }, [terms, addToast]);

  const handleResetFilter = useCallback(() => {
    const fields = ['year', 'counselor', 'attorney', 'category', 'agency'];

    fields.forEach(field => {
      const fieldRef = filterFormRef.current?.getFieldRef(field);
      fieldRef.select.clearValue();
    });

    setOpinions(initialOpinions);
  }, [initialOpinions]);

  return (
    <div>
      <Header />
      <PageHeader
        title="Resultados da busca"
        description={`Termos: ${terms || '""'}`}
      />
      <Container className="container">
        <Results>
          <OpinionsList>
            {opinions.map(opinion => (
              <Opinion key={opinion.id} opinion={opinion} />
            ))}
          </OpinionsList>

          {filtersVisible && (
            <Filters className="filters" active={activeFilters}>
              <div className="filter-header">
                <div className="filter-title">
                  <FiFilter size="2rem" />
                  <span>Filtrar resultados</span>
                </div>

                <button type="button" id="toggle-filters">
                  <FiChevronDown size="2rem" onClick={toggleFilters} />
                </button>
              </div>

              <Form
                ref={filterFormRef}
                onSubmit={filterOpinions}
                className="filter-content"
              >
                <fieldset>
                  <legend>Exercício</legend>
                  <Select
                    options={years}
                    name="year"
                    placeholder="Selecione um ano"
                  />
                </fieldset>
                <fieldset>
                  <legend>Conselheiro</legend>
                  <Select
                    options={counselors}
                    name="counselor"
                    placeholder="Selecione um conselheiro"
                  />
                </fieldset>
                <fieldset>
                  <legend>Procurador de Contas</legend>
                  <Select
                    options={attorneys}
                    name="attorney"
                    placeholder="Procurador de Contas"
                  />
                </fieldset>
                <fieldset>
                  <legend>Categoria</legend>
                  <Select
                    options={categories}
                    name="category"
                    placeholder="Selecione uma categoria"
                  />
                </fieldset>

                <fieldset>
                  <legend>Unidade Jurisdicionada</legend>
                  <Select
                    options={agencies}
                    name="agency"
                    placeholder="Selecione uma unidade jurisdicionada"
                  />
                </fieldset>
                <Button type="submit">Aplicar filtros</Button>
                <button
                  className="clear-filters"
                  type="button"
                  onClick={handleResetFilter}
                >
                  Limpar filtros
                </button>
              </Form>
            </Filters>
          )}
        </Results>
      </Container>
    </div>
  );
};

export default Search;
