import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0.3rem solid var(--color-secondary);
  height: 5.6rem;
  width: 6rem;
  padding: 0;
  border-radius: 0.5rem;
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 1.8rem;
  text-decoration: none;
  margin-top: 1.6rem;
  transition: background-color 0.7s;
  transition: color 0.7s;

  &:hover {
    background: ${shade(0.1, '#4361EE')};
    color: var(--color-light);
  }
`;
