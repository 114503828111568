import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media (min-width: 769px) and (max-width: 1120px) {
    padding: 3rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 3rem;
    width: 100%;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .right {
    display: flex;
    justify-content: space-between;
  }

  .left {
    display: flex;
    justify-content: space-between;
  }

  .btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-secondary);
    height: 4.8rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#4361EE')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    height: 4.8rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#CE1D37')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  .go-back {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    height: 4.8rem;
    border-radius: 0.5rem;
    border: 1px solid var(--color-light);
    padding: 0 2rem;
    color: var(--color-text-light);
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      background: var(--color-light);
      color: var(--color-title-dark);
    }

    & + button {
      margin-left: 2rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .left,
    .right {
      flex-direction: column;
      width: 100%;

      .go-back,
      .btn-secondary,
      .btn-primary {
        width: 100%;

        & + button {
          margin-left: 0;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40rem;
  background: var(--color-light);
  padding: 6rem;

  border-radius: 1rem;
  -webkit-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);

  ul {
    li {
      list-style: none;
      font-family: Poppins;
      color: var(--color-dark);
      line-height: 2.5rem;

      & + li {
        margin-top: 1.5rem;
      }

      strong {
        font-size: 1.8rem;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-secondary);
      height: 5.6rem;
      border-radius: 0.5rem;
      border: 0;
      padding: 0 2rem;
      color: var(--color-light);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.1, '#4361EE')};
      }

      & + button {
        margin-left: 2rem;
      }
    }

    .button-outline {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0.3rem solid var(--color-secondary);
      height: 6rem;
      width: 6rem;
      padding: 0;
      border-radius: 0.5rem;
      color: var(--color-secondary);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.7s;
      transition: color 0.7s;

      &:hover {
        background: ${shade(0.1, '#4361EE')};
        color: var(--color-light);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;

    ul {
      li {
        display: flex;
        flex-direction: column;

        span {
          background: #fafafa;
          border-radius: 0.4rem;
          padding: 1rem;
          margin-top: 1rem;
        }
      }
    }

    footer {
      margin-top: 2rem;

      a {
        width: 100%;
      }
    }
  }
`;
