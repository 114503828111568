import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import ReactPaginate from 'react-paginate';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Opinion from '../../components/Opinion';
import { Container, Toolbar, OpinionsList } from './styles';
import api from '../../services/api';

interface OpinionData {
  id: string;
  title: string;
  description: string;
  date: string;
  counselor: string;
  attorney: string;
  category_id: string;
  agency_id: string;
}

const PER_PAGE = 10;

const ListOpinions: React.FC = () => {
  const [opinions, setOpinions] = useState<OpinionData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    api.get('/opinions').then(response => {
      setOpinions(response.data);
    });
  }, []);

  const handlePageClick = useCallback(({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }, []);

  const offset = currentPage * PER_PAGE;
  const offsetPage = useMemo(() => {
    return opinions.length < 10 ? opinions.length : offset + PER_PAGE;
  }, [offset, opinions.length]);

  const currentPageData = opinions.slice(offset, offset + PER_PAGE);

  const pageCount = Math.ceil(opinions.length / PER_PAGE);

  return (
    <div>
      <Header />
      <PageHeader title="Pareceres cadastrados" />
      <Container>
        <Toolbar>
          <Link to="/pareceres/novo">Novo parecer</Link>
        </Toolbar>
        <OpinionsList className="opinions">
          {currentPageData.map(opinion => (
            <Opinion key={opinion.id} opinion={opinion} />
          ))}

          <strong>
            {`Exibindo resultados de ${offset} à ${offsetPage} de ${opinions.length}.`}
          </strong>
          <ReactPaginate
            previousLabel={<MdChevronLeft />}
            nextLabel={<MdChevronRight />}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        </OpinionsList>
      </Container>
    </div>
  );
};

export default ListOpinions;
