import styled, { css } from 'styled-components';

interface ContainerProps {
  hasTitle: boolean;
}

export const Container = styled.header<ContainerProps>`
  background: var(--gradient-red);
  width: 100vw;
  height: 26rem;
  display: flex;
  justify-content: center;
  padding-top: 8rem;
  margin-bottom: -8rem;

  ${props =>
    !props.hasTitle &&
    css`
      margin-bottom: -20rem;
    `}

  .header-content {
    width: 100%;
    max-width: 112rem;
    font-family: Poppins;
    font-weight: 600;
    color: var(--color-text-light);

    h1 {
      font-size: 4.8rem;
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    width: 100vw;
    height: 20.8rem;
    padding: 5rem 2rem 0 2rem;

    .header-content {
      width: 100%;
      padding: 2rem;

      h1 {
        font-size: 2.4rem;
      }

      p {
        font-weight: 300;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: 20.8rem;
    padding: 4.9rem 2rem 0 2rem;

    .header-content {
      width: 100%;

      h1 {
        font-size: 2.4rem;
      }

      p {
        font-weight: 300;
      }
    }
  }
`;
