import React, { useCallback, useRef } from 'react';

interface YearMonthFormProps {
  date: Date;
  onChange: (date: Date) => void;
  fromMonth: Date;
  toMonth: Date;
  months: string[];
}

const YearMonthForm: React.FC<YearMonthFormProps> = ({
  date,
  onChange,
  fromMonth,
  toMonth,
  months,
}) => {
  const yearRef = useRef<HTMLSelectElement>(null);
  const monthRef = useRef<HTMLSelectElement>(null);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = useCallback(() => {
    if (yearRef.current && monthRef.current) {
      const year = Number(yearRef.current.value);
      const month = Number(monthRef.current.value);
      onChange(new Date(year, month));
    }
  }, [onChange]);

  return (
    <div className="DayPicker-Caption">
      <select
        ref={yearRef}
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <select
        ref={monthRef}
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearMonthForm;
