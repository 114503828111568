import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useRouteMatch } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import filesize from 'filesize';
import { FiAlertTriangle, FiFile } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  UploadFileContainer,
  UploadFileFooter,
} from './styles';

import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import DayPicker from '../../components/DayPicker';
import Dropzone from '../../components/Dropzone';
import FileList from '../../components/FileList';

import api from '../../services/api';
import { attorneys, counselors } from '../../data/data';
import getValidationErrors from '../../utils/getValidationErrors';

interface OpinionParams {
  id: string;
}

interface Opinion {
  id: string;
  opinion_number: string;
  opinion_year: number;
  opinion_date: Date;
  lawsuit_number: string;
  lawsuit_year: number;
  lawsuit_date: Date;
  counselor: string;
  attorney: string;
  description: string;
  file_url?: string;
  interested: string;
  subject: string;
  opinionDateFormatted: string;
  lawsuitDateFormatted: string;
  category: {
    id: string;
    title: string;
  };
  agency: {
    id: string;
    title: string;
  };
}

interface Category {
  id: string;
  title: string;
}

interface Agency {
  id: string;
  title: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

const EditOpinion: React.FC = () => {
  const [opinion, setOpinion] = useState<Opinion | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);

  const { params } = useRouteMatch<OpinionParams>();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  // Get Categories and Agencies
  useEffect(() => {
    api.get(`/opinions/${params.id}`).then(response => {
      const opinionDateFormatted = format(
        parseISO(response.data.opinion_date),
        'yyyy-MM-dd',
      );

      const lawsuitDateFormatted = format(
        parseISO(response.data.lawsuit_date),
        'yyyy-MM-dd',
      );

      const opinionFormatted = {
        ...response.data,
        opinionDateFormatted,
        lawsuitDateFormatted,
      };
      setOpinion(opinionFormatted);
    });

    api.get(`/categories`).then(response => {
      const categoriesFormatted = response.data.map((category: Category) => {
        return {
          value: category.id,
          label: category.title,
        };
      });
      setCategories(categoriesFormatted);
    });

    api.get(`/agencies`).then(response => {
      const agenciesFormatted = response.data.map((agency: Agency) => {
        return {
          value: agency.id,
          label: agency.title,
        };
      });
      setAgencies(agenciesFormatted);
    });
  }, [params.id]);

  // Submit Form
  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          opinion_number: Yup.string().required(
            'Número do parecer obrigatório',
          ),
          opinion_year: Yup.number().required('Ano do parecer obrigatório'),
          opinion_date: Yup.date()
            .required('Data do parecer obrigatória')
            .typeError('Data do parecer obrigatória'),
          lawsuit_number: Yup.string().required(
            'Número do processo obrigatório',
          ),
          lawsuit_year: Yup.number().required('Ano do processo obrigatório'),
          lawsuit_date: Yup.date()
            .required('Data do processo obrigatória')
            .typeError('Data do processo obrigatória'),
          interested: Yup.string().required('Nome do interessado obrigatório'),
          subject: Yup.string().required('Assunto obrigatório'),
          category_id: Yup.string().required('Categoria obrigatória'),
          agency_id: Yup.string().required(
            'Unidade Jurisdicionada obrigatória',
          ),
          counselor: Yup.string().required('Conselheiro obrigatório'),
          attorney: Yup.string().required('Procurador de Contas obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put(`/opinions/${params.id}`, data);
        const opinionDateFormatted = format(
          parseISO(response.data.opinion_date),
          'yyyy-MM-dd',
        );
        const lawsuitDateFormatted = format(
          parseISO(response.data.lawsuit_date),
          'yyyy-MM-dd',
        );
        const opinionFormatted = {
          ...response.data,
          opinionDateFormatted,
          lawsuitDateFormatted,
        };
        setOpinion(opinionFormatted);
        window.scrollTo(0, 0);
        addToast({
          type: 'success',
          title: 'Parecer atualizado',
          description: 'O parecer foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao editar parecer',
          description:
            'Ocorreu um erro ao atualizar o parecer, por favor, cheque as informações',
        });
      }
    },
    [addToast, params.id],
  );

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    if (!uploadedFiles.length) return;

    const file = uploadedFiles[0];

    data.append('file', file.file, file.name);

    try {
      const response = await api.patch(`/opinions/${params.id}/file`, data);

      const opinionDateFormatted = format(
        parseISO(response.data.opinion_date),
        'yyyy-MM-dd',
      );
      const lawsuitDateFormatted = format(
        parseISO(response.data.lawsuit_date),
        'yyyy-MM-dd',
      );
      const opinionFormatted = {
        ...response.data,
        opinionDateFormatted,
        lawsuitDateFormatted,
      };
      setOpinion(opinionFormatted);
      window.scrollTo(0, 0);
      addToast({
        type: 'success',
        title: 'Arquivo enviado',
        description: 'O arquivo do parecer foi atualizado com sucesso.',
      });
    } catch (err) {
      window.scrollTo(0, 0);
      addToast({
        type: 'error',
        title: 'Erro ao atualizar arquivo',
        description:
          'Ocorreu um erro ao enviar o arquivo. Apenas arquivos PDF são aceitos!',
      });
    }
  }, [addToast, params.id, uploadedFiles]);

  const submitFile = useCallback((files: File[]): void => {
    const uploadFiles = files.map(file => ({
      file,
      name: file.name,
      readableSize: filesize(file.size),
    }));

    setUploadedFiles(uploadFiles);
  }, []);

  return (
    <>
      <Header />
      <PageHeader title="Editar parecer" />
      <Container>
        {opinion && (
          <Content>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                opinion_number: opinion.opinion_number,
                opinion_year: opinion.opinion_year,
                opinion_date: opinion.opinion_date,
                lawsuit_number: opinion.lawsuit_number,
                lawsuit_year: opinion.lawsuit_year,
                interested: opinion.interested,
                subject: opinion.subject,
                counselor: opinion.counselor,
                attorney: opinion.attorney,
                description: opinion.description,
                agency_id: opinion.agency ? opinion.agency.id : undefined,
                category_id: opinion.category ? opinion.category.id : undefined,
              }}
            >
              <fieldset className="field">
                <legend>Dados do parecer</legend>
                <hr />
                <div className="form-row">
                  <Input
                    name="opinion_number"
                    placeholder="Número do parecer"
                  />
                  <Input name="opinion_year" placeholder="Ano do parecer" />
                  <DayPicker
                    name="opinion_date"
                    value={opinion.opinionDateFormatted}
                    placeholder="Data do parecer"
                  />
                </div>
                <div className="form-row">
                  <Input
                    name="lawsuit_number"
                    placeholder="Número do processo"
                  />
                  <Input name="lawsuit_year" placeholder="Ano do processo" />
                  <DayPicker
                    name="lawsuit_date"
                    value={opinion.lawsuitDateFormatted}
                    placeholder="Data do parecer"
                  />
                </div>
                <div className="form-row">
                  <Input name="interested" placeholder="Interessado" />
                  <Input name="subject" placeholder="Assunto" />
                </div>
                <div className="form-row">
                  <Select
                    options={categories}
                    name="category_id"
                    placeholder="Categoria"
                    defaultInputValue={
                      opinion.category ? opinion.category.title : ''
                    }
                    defaultValue={{
                      value: opinion.category ? opinion.category.id : '',
                    }}
                  />
                  <Select
                    options={agencies}
                    name="agency_id"
                    placeholder="Unidade Jurisdicionada"
                    defaultInputValue={
                      opinion.agency ? opinion.agency.title : ''
                    }
                    defaultValue={{
                      value: opinion.agency ? opinion.agency.id : '',
                    }}
                  />
                </div>
                <div className="form-row">
                  <Select
                    options={counselors}
                    name="counselor"
                    placeholder="Conselheiro Relator"
                    defaultInputValue={opinion.counselor}
                    defaultValue={{ value: opinion.counselor }}
                  />
                  <Select
                    options={attorneys}
                    name="attorney"
                    placeholder="Procurador de Contas"
                    defaultInputValue={opinion.attorney}
                    defaultValue={{ value: opinion.attorney }}
                  />
                </div>
                <div className="form-row">
                  <TextArea name="description" placeholder="Descrição" />
                </div>
              </fieldset>
              <footer>
                <button type="submit" title="Enviar">
                  Enviar
                </button>
              </footer>
            </Form>

            <UploadFileContainer>
              <h2>Arquivo do parecer</h2>

              {opinion.file_url ? (
                <a
                  href={opinion.file_url}
                  title="Ver arquivo do parecer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  <FiFile color="var(--color-primary)" size="1.8rem" />
                  Arquivo atual
                </a>
              ) : (
                <span>
                  Este parecer ainda não possui um arquivo cadastrado.
                </span>
              )}

              <h3>Enviar arquivo do parecer</h3>
              <Dropzone onUpload={submitFile} />
              {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
              <UploadFileFooter>
                <p>
                  <FiAlertTriangle
                    color="var(--color-primary-light)"
                    size="1.6rem"
                  />
                  Permitido apenas arquivos PDF
                </p>
                <button onClick={handleUpload} type="button">
                  Enviar arquivo
                </button>
              </UploadFileFooter>
            </UploadFileContainer>
          </Content>
        )}
      </Container>
    </>
  );
};

export default EditOpinion;
