import React, { useState } from 'react';
import {
  MdInsertDriveFile,
  MdFavorite,
  MdPeople,
  MdPerson,
  MdPowerSettingsNew,
  MdMenu,
  MdClose,
} from 'react-icons/md';
import { FiChevronDown, FiPower, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import logoImg from '../../assets/logo.svg';

import { Container, Content, Navbar } from './styles';
import { useAuth } from '../../hooks/auth';

const Header: React.FC = () => {
  const { signOut, user } = useAuth();

  const [active, setActive] = useState(false);

  return (
    <Container>
      <Content>
        <Link to="/">
          <img src={logoImg} alt="Buscador MPC" />
        </Link>

        <Navbar>
          <div className="nav-menu">
            {user.is_admin && (
              <>
                <Link to="/usuarios">
                  <MdPeople size="2.4rem" />
                  Usuários
                </Link>
                <Link to="/pareceres">
                  <MdInsertDriveFile size="2.4rem" />
                  Pareceres
                </Link>
              </>
            )}
            <Link to="/favoritos">
              <MdFavorite size="2.4rem" />
              Favoritos
            </Link>
            <span className="dropdown">
              <FiChevronDown size="2.4rem" />
              {user.name}
              <div className="dropdown-content">
                <Link to="/me">
                  <FiUser size="1.4rem" />
                  Editar perfil
                </Link>

                <button type="button" onClick={signOut}>
                  <FiPower size="1.4rem" />
                  Sair
                </button>
              </div>
            </span>
          </div>
          <div className="menu-toggle">
            {active ? (
              <MdClose size="3.2rem" onClick={() => setActive(!active)} />
            ) : (
              <MdMenu size="3.2rem" onClick={() => setActive(!active)} />
            )}
          </div>
          {active && (
            <div className="mobile-menu">
              <span>{user.name}</span>
              {user.is_admin && (
                <>
                  <Link to="/usuarios">
                    <MdPeople size="2.4rem" />
                    Usuários
                  </Link>
                  <Link to="/pareceres">
                    <MdInsertDriveFile size="2.4rem" />
                    Pareceres
                  </Link>
                </>
              )}
              <Link to="/favoritos">
                <MdFavorite size="2.4rem" />
                Favoritos
              </Link>
              <Link to="/me">
                <MdPerson size="2.4rem" />
                Editar perfil
              </Link>
              <button type="button" onClick={signOut}>
                <MdPowerSettingsNew size="2.4rem" />
                Sair
              </button>
            </div>
          )}
        </Navbar>
      </Content>
    </Container>
  );
};

export default Header;
