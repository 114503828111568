import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media (min-width: 769px) and (max-width: 1120px) {
    padding: 3rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;

    padding: 0 2rem 2rem 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50rem;
  background: var(--color-light);
  padding: 4rem;
  box-sizing: border-box !important;

  border-radius: 1rem;
  -webkit-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);

  .user-data {
    margin-bottom: 3rem;

    h2 {
      font-family: Poppins;
      font-weight: 600;
      font-size: 2.4rem;
      color: var(--color-dark);
    }

    hr {
      margin: 0.5rem 0 3rem;
      border-top: 0.1rem solid var(--color-gray);
      border-bottom: none;
    }

    ul {
      li {
        list-style: none;
        font-family: Poppins;
        color: var(--color-dark);
        line-height: 2.5rem;

        & + li {
          margin-top: 1.5rem;
        }

        strong {
          font-size: 1.8rem;
        }
      }
    }
  }

  form {
    margin-bottom: 3rem;

    fieldset {
      border: none;
      display: flex;
      flex-direction: column;
      box-sizing: border-box !important;
      width: 100%;

      legend {
        font-family: Poppins;
        font-weight: 600;
        font-size: 2.4rem;
        color: var(--color-dark);
      }

      hr {
        margin: 0.5rem 0 3rem;
        border-top: 0.1rem solid var(--color-gray);
        border-bottom: none;
      }

      .form-row {
        display: flex;
        flex-direction: row;
        margin: 0 -1.5rem;
        box-sizing: border-box !important;

        & + .form-row {
          margin-top: 3rem;
        }

        > div {
          margin-top: 0;
          box-sizing: border-box !important;

          & {
            margin: 0 1.5rem;
            box-sizing: border-box !important;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-primary-light);
      height: 5rem;
      min-width: 18rem;
      border-radius: 0.5rem;
      border: 0;
      padding: 0 2rem;
      color: var(--color-light);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.1, '#D12D45')};
      }

      & + button {
        margin-left: 2rem;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 3rem;

    ul {
      li {
        display: flex;
        flex-direction: column;

        span {
          background: #fafafa;
          border-radius: 0.4rem;
          padding: 1rem;
          margin-top: 1rem;
        }
      }
    }

    form {
      margin-bottom: 0;
    }

    button {
      width: 100%;
    }
  }
`;
