import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { FavoritesProvider } from './favorites';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>
      <FavoritesProvider>{children}</FavoritesProvider>
    </ToastProvider>
  </AuthProvider>
);

export default AppProvider;
