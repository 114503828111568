import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiLock } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';

import { Container, Content } from './styles';

import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import Input from '../../components/Input';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';

interface ProfileFormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const { user } = useAuth();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string().required('Campo obrigatório'),
          password: Yup.string().when('old_password', {
            is: val => !!val.length,
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string(),
          }),
          password_confirmation: Yup.string()
            .when('old_password', {
              is: val => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.patch('/profile', data);

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description:
            'Suas informações do perfil foram atualizadas com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro ao atualizar o perfil, tente novamente.',
        });
      }
    },
    [addToast],
  );

  return (
    <div>
      <Header />
      <PageHeader title="Editar perfil" />
      <Container>
        <Content>
          <div className="user-data">
            <h2>Meus Dados</h2>
            <hr />
            <ul>
              <li>
                <strong>Nome: </strong>
                <span>{user.name}</span>
              </li>
              <li>
                <strong>E-mail: </strong>
                <span>{user.email}</span>
              </li>
            </ul>
          </div>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <fieldset>
              <legend>Alterar senha</legend>
              <hr />
              <Input
                name="old_password"
                icon={FiLock}
                placeholder="Senha atual"
                type="password"
              />
              <Input
                name="password"
                icon={FiLock}
                placeholder="Nova senha"
                type="password"
              />
              <Input
                name="password_confirmation"
                icon={FiLock}
                placeholder="Confirmar senha"
                type="password"
              />
            </fieldset>
            <footer>
              <button type="submit" title="Confirmar mudanças">
                Confirmar mudanças
              </button>
            </footer>
          </Form>
        </Content>
      </Container>
    </div>
  );
};

export default Profile;
