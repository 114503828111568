import React, { useEffect, useState } from 'react';
import { RiEmotionSadLine } from 'react-icons/ri';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Opinion from '../../components/Opinion';
import { Container, Card } from './styles';
import api from '../../services/api';

interface OpinionData {
  id: string;
  title: string;
  description: string;
}

const Favorites: React.FC = () => {
  const [opinions, setOpinions] = useState<OpinionData[]>([]);

  useEffect(() => {
    api.get<OpinionData[]>('/favorites').then(response => {
      setOpinions(response.data);
    });
  }, []);

  return (
    <div>
      <Header />
      <PageHeader title="Meus Favoritos" />
      <Container>
        {opinions.length ? (
          opinions.map(opinion => (
            <Opinion key={opinion.id} opinion={opinion} />
          ))
        ) : (
          <Card>
            <RiEmotionSadLine size="20rem" />
            <h2> Sua lista de favoritos está vazia. </h2>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default Favorites;
