import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 720px;
  background: var(--color-light);
  padding: 3rem;
  border-radius: 1.6rem;
  -webkit-box-shadow: 0.6rem 0.6rem 2rem 0.6rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0.6rem 0.6rem 2rem 0.6rem rgba(0, 0, 0, 0.1);
  box-shadow: 0.6rem 0.6rem 2rem 0.6rem rgba(0, 0, 0, 0.1);

  .img-404 {
    height: 25rem;
    max-width: 100%;
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 3.2rem;
    color: var(--color-dark);
  }

  h3 {
    color: var(--color-text-base);
  }

  a {
    padding: 1.6rem 2.4rem;
    background: var(--gradient-red);
    border-radius: 0.8rem;
    color: var(--color-text-light);
    margin-top: 2.4rem;
    transition: 0.2s ease-in;
    background-size: 150% auto;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      background-position: right center;
    }
  }

  .mpc-footer {
    display: flex;
    flex-direction: column;
    img {
      height: 5.6rem;
      margin-top: 3.2rem;
      margin-bottom: 1rem;
    }

    span {
      font-family: 'Roboto', sans-serif;
      color: var(--color-dark);
      font-size: 1.2rem;
    }
  }

  @media (max-width: 768px) {
    .img-404 {
      height: 25rem;
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }
`;
