import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--gradient-red);

  @media (max-width: 768px) {
    padding: 0 3rem;
  }
`;

export const ContentContainer = styled.div`
  background: var(--color-light);
  height: 56rem;
  width: 50rem;
  border-radius: 1rem;
  padding: 3.2rem;

  display: flex;
  flex-direction: column;

  -webkit-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);

  img {
    height: 18rem;
    margin-bottom: 6rem;
  }

  @media (max-width: 768px) {
    max-height: 424px;

    img {
      height: 14.4rem;
      margin-bottom: 2rem;
    }
  }
`;
