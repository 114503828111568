import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import img404 from '../../assets/404.svg';
import mpcLogo from '../../assets/mpc-logo.svg';

import { Container, Content } from './styles';

const Page404: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <Header />
      <PageHeader />
      <Container className="container">
        <Content className="card-shadow">
          <img className="img-404" src={img404} alt="404" />
          <h2>Ooops!</h2>
          <h3>Página não encontrada</h3>

          <Link to="/">Voltar ao início</Link>

          <div className="mpc-footer">
            <img src={mpcLogo} alt="MPC-MT" />
            <span>{`MPC-MT © ${year}`}</span>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default Page404;
