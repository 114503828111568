import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api';
import { useToast } from './toast';

interface Favorite {
  id: string;
}

interface FavoritesContextData {
  favorites: string[];
  checkIsFavorite(id: string): boolean;
  toggleFavorite(id: string): void;
}

const FavoritesContext = createContext<FavoritesContextData>(
  {} as FavoritesContextData,
);

const FavoritesProvider: React.FC = ({ children }) => {
  const [favorites, setFavorites] = useState<string[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    api.get(`/favorites`).then(response => {
      const favoriteOpinions = response.data.map((item: Favorite) => {
        return item.id;
      });
      setFavorites(favoriteOpinions);
    });
  }, []);

  const checkIsFavorite = useCallback(
    (id: string) => {
      return !!favorites.includes(id);
    },
    [favorites],
  );

  const toggleFavorite = useCallback(
    async (id: string) => {
      const response = await api.post(`/favorites/${id}`);
      const responseFavorites = response.data.map((item: Favorite) => {
        return item.id;
      });

      const findFavorite = responseFavorites.includes(id);

      if (!findFavorite) {
        addToast({
          type: 'info',
          title: 'Removido dos favoritos',
          description: 'O parecer foi removido da sua lista de favoritos.',
        });
      } else {
        addToast({
          type: 'success',
          title: 'Adicionado aos favoritos',
          description: 'O parecer foi adicionado aos favoritos com sucesso.',
        });
      }

      setFavorites(responseFavorites);
    },
    [addToast],
  );

  // console.log(favorites);

  return (
    <FavoritesContext.Provider
      value={{ favorites, checkIsFavorite, toggleFavorite }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

function useFavorites(): FavoritesContextData {
  const context = useContext(FavoritesContext);

  if (!context)
    throw new Error('useContext must br used within a FvoritesProvider');

  return context;
}

export { FavoritesProvider, useFavorites };
