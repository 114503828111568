import React, { useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { confirmAlert } from 'react-confirm-alert';
import { FiChevronLeft } from 'react-icons/fi';
import { Container, Toolbar, Content } from './styles';
import { useToast } from '../../hooks/toast';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FavoriteButton from '../../components/FavoriteButton';

import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface OpinionParams {
  id: string;
}

interface Opinion {
  id: string;
  title: string;
  opinion_date: Date;
  counselor: string;
  attorney: string;
  description: string;
  file_url: string;
  opinionDateFormatted: string;
  lawsuitDateFormatted: string;
  category: {
    title: string;
  };
  agency: {
    title: string;
  };
}

const Details: React.FC = () => {
  const [opinion, setOpinion] = useState<Opinion | null>(null);
  const { user } = useAuth();
  const { params } = useRouteMatch<OpinionParams>();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    api.get(`/opinions/${params.id}`).then(response => {
      const opinionDateFormatted = format(
        parseISO(response.data.opinion_date),
        'dd/MM/YYY',
      );
      const lawsuitDateFormatted = format(
        parseISO(response.data.lawsuit_date),
        'dd/MM/YYY',
      );
      const opinionFormatted = {
        ...response.data,
        opinionDateFormatted,
        lawsuitDateFormatted,
      };

      setOpinion(opinionFormatted);
    });
  }, [params.id]);

  const handleDelete = useCallback(async () => {
    try {
      confirmAlert({
        title: 'Confirme a exclusão',
        message:
          'Tem certeza que deseja excluir o parecer? Esta ação não poderá ser desfeita.',
        buttons: [
          {
            label: 'Excluir',
            onClick: () => {
              api.delete(`/opinions/${params.id}`);

              history.push('/');
              addToast({
                type: 'success',
                title: 'Parecer excluído',
                description: 'Parecer foi excluído com sucesso!',
              });
            },
          },
          {
            label: 'Cancelar',
            onClick: () => undefined,
          },
        ],
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description:
          'Ocorreu um erro ao excluir o parecer, por favor, tente novamente.',
      });
    }
  }, [addToast, history, params.id]);

  return (
    <div>
      <Header />
      <PageHeader title={opinion?.title} description={opinion?.description} />
      <Container>
        {opinion && (
          <>
            <Toolbar>
              <div className="left">
                {history.length > 2 && (
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="go-back"
                  >
                    <FiChevronLeft size="1.6rem" />
                    Voltar
                  </button>
                )}
              </div>

              {user.is_admin && (
                <div className="right">
                  <Link
                    to={`/pareceres/${opinion.id}/editar`}
                    className="btn-secondary"
                  >
                    Editar parecer
                  </Link>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="btn-primary"
                  >
                    Excluir parecer
                  </button>
                </div>
              )}
            </Toolbar>

            <Content>
              <ul>
                <li>
                  <strong>Assunto: </strong>
                  <span>
                    {opinion?.category ? opinion?.category.title : ''}
                  </span>
                </li>
                <li>
                  <strong>Exercício: </strong>
                  <span>{opinion?.opinionDateFormatted}</span>
                </li>
                <li>
                  <strong>Conselheiro: </strong>
                  <span>{opinion?.counselor}</span>
                </li>
                <li>
                  <strong>Procurador de Contas: </strong>
                  <span>{opinion?.attorney}</span>
                </li>
                <li>
                  <strong>Unidade Jurisdicionada: </strong>
                  <span>{opinion?.agency ? opinion.agency.title : ''}</span>
                </li>
                <li>
                  <strong>Categoria: </strong>
                  <span>{opinion?.category ? opinion.category.title : ''}</span>
                </li>
                <li>
                  <strong>Descrição: </strong>
                  <span>{opinion?.description}</span>
                </li>
              </ul>
              <footer>
                {opinion.file_url ? (
                  <a
                    href={opinion.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir documento
                  </a>
                ) : (
                  <span>
                    Este parecer ainda não possui um arquivo cadastrado.
                  </span>
                )}
                {opinion && <FavoriteButton opinion_id={opinion.id} />}
              </footer>
            </Content>
          </>
        )}
      </Container>
    </div>
  );
};

export default Details;
