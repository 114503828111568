import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: var(--color-primary);
  color: var(--color-light);
  bottom: 5%;
  right: 5%;
  padding: 1.6rem;
  border-radius: 50%;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.2);
  transition: 0.2s ease-in;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  &:hover {
    background: ${shade(0.05, '#CE1D37')};
    transform: scale(1.1);
  }

  &.animated {
    animation: pulse 0.7s;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0px 0px 0px 0px rgba(206, 29, 55, 1);
    }
    100% {
      box-shadow: 0px 0px 0px 25px rgba(206, 29, 55, 0);
    }
  }
`;
