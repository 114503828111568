import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media (min-width: 769px) and (max-width: 1120px) {
    padding: 3rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50rem;
  background: var(--color-light);
  padding: 4rem;
  box-sizing: border-box !important;

  border-radius: 1rem;
  -webkit-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);

  form {
    border-bottom: 0.1rem solid var(--color-gray);
    padding-bottom: 3rem;
    margin-bottom: 3rem;

    fieldset {
      border: none;
      display: flex;
      flex-direction: column;
      box-sizing: border-box !important;
      width: 100%;

      legend {
        font-family: Poppins;
        font-weight: 600;
        font-size: 2.4rem;
        color: var(--color-dark);
      }

      hr {
        margin: 0.5rem 0 3rem;
        border-top: 0.1rem solid var(--color-gray);
        border-bottom: none;
      }

      .form-row {
        display: flex;
        flex-direction: row;
        margin: 0 -1.5rem;
        box-sizing: border-box !important;
        width: 100%;

        & + .form-row {
          margin-top: 3rem;
        }

        > div {
          margin-top: 0;
          box-sizing: border-box !important;

          & {
            margin: 0 1.5rem;
            box-sizing: border-box !important;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-primary-light);
      height: 5rem;
      min-width: 18rem;
      border-radius: 0.5rem;
      border: 0;
      padding: 0 2rem;
      color: var(--color-light);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.1, '#D12D45')};
      }

      & + button {
        margin-left: 2rem;
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    width: 100%;
    padding: 2rem;

    form {
      display: flex;
      flex-direction: column;

      fieldset {
        hr {
          margin: 1rem 0;
        }

        .form-row {
          display: flex;
          margin: 0;
          width: 100%;

          & + .form-row {
            margin: 0;
          }

          > div {
            width: 100%;
            margin: 1.2rem 0.5rem;

            .react-select__input > input {
              max-width: 250px;
            }
          }
        }
      }
    }

    footer {
      margin-top: 1rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem;

    form {
      display: flex;
      flex-direction: column !important;

      fieldset {
        hr {
          margin: 1rem 0;
        }

        .form-row {
          display: flex;
          flex-direction: column !important;
          margin: 0;
          width: 100%;

          & + .form-row {
            margin: 0;
          }

          > div {
            width: 100%;
            margin: 1.2rem 0;

            .react-select__input > input {
              max-width: 200px;
            }
          }
        }
      }
    }

    footer {
      margin-top: 1rem;

      button {
        width: 100%;
      }
    }
  }
`;

export const UploadFileContainer = styled.div`
  width: 100%;

  h2 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 2.4rem;
    color: var(--color-dark);
    margin-bottom: 2rem;
  }

  h3 {
    font-family: Poppins;
    font-weight: 400;
    color: var(--color-title-dark);
    margin: 2rem 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: var(--color-primary-light);
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#D12D45')};
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;

    a {
      background: var(--color-background);
      padding: 1rem;
      border-radius: 0.4rem;
    }
  }
`;

export const UploadFileFooter = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #969cb3;

    svg {
      margin-right: 0.5rem;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-secondary);
    height: 5rem;
    min-width: 18rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0 2rem;
    color: var(--color-light);
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: none;
    margin-top: 1.6rem;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.1, '#4361EE')};
    }

    & + button {
      margin-left: 2rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 0;

    button {
      width: 100%;
    }
  }
`;
