import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MdChevronLeft,
  MdChevronRight,
  MdEdit,
  MdDelete,
} from 'react-icons/md';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

import ReactPaginate from 'react-paginate';

import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import { Container, Toolbar, UsersList } from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

interface UserData {
  id: string;
  name: string;
  email: string;
  is_admin: boolean;
}

const PER_PAGE = 10;

const List: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const { addToast } = useToast();

  const { user: authUser } = useAuth();

  /**
   * Get users from api
   */
  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);
    });
  }, []);

  /**
   * Pagination
   */
  const handlePageClick = useCallback(({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }, []);

  const offset = currentPage * PER_PAGE;
  const offsetPage = useMemo(() => {
    return users.length < 10 ? users.length : offset + PER_PAGE;
  }, [offset, users.length]);

  const currentPageData = users.slice(offset, offset + PER_PAGE);

  const pageCount = Math.ceil(users.length / PER_PAGE);

  /**
   * Delete user
   */

  const handleDelete = useCallback(
    async (user_id: string) => {
      try {
        confirmAlert({
          title: 'Confirme a exclusão',
          message:
            'Tem certeza que deseja excluir o usuário? Esta ação não poderá ser desfeita.',
          buttons: [
            {
              label: 'Excluir',
              onClick: async () => {
                if (authUser.id === user_id) {
                  return;
                }

                await api.delete(`/users/${user_id}`);

                addToast({
                  type: 'success',
                  title: 'Usuário excluído',
                  description: 'O usuário foi excluído com sucesso!',
                });

                setUsers(users.filter(user => user.id !== user_id));
              },
            },
            {
              label: 'Cancelar',
              onClick: () => undefined,
            },
          ],
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão',
          description:
            'Ocorreu um erro ao excluir o usuário, por favor, tente novamente.',
        });
      }
    },
    [addToast, authUser.id, users],
  );

  return (
    <div>
      <Header />
      <PageHeader title="Usuários cadastrados" />
      <Container>
        <Toolbar>
          <Link to="/usuarios/novo">Novo usuário</Link>
        </Toolbar>
        <UsersList className="opinions">
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th className="desktop-only">E-mail</th>
                <th>Função</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map(user => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td className="desktop-only">{user.email}</td>
                  <td>{user.is_admin ? 'Administrador' : 'Usuário'}</td>
                  <td>
                    <Link
                      to={`/usuarios/${user.id}/editar`}
                      title="Editar usuário"
                    >
                      <MdEdit size={18} />
                    </Link>

                    {authUser.id !== user.id && (
                      <button
                        type="button"
                        onClick={() => handleDelete(user.id)}
                      >
                        <MdDelete size={18} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <strong>
            {`Exibindo resultados de ${offset + 1} à ${offsetPage} de ${
              users.length
            }.`}
          </strong>
          <ReactPaginate
            previousLabel={<MdChevronLeft />}
            nextLabel={<MdChevronRight />}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        </UsersList>
      </Container>
    </div>
  );
};

export default List;
