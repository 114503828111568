import styled from 'styled-components';

import searchBackground from '../../assets/search.svg';

export const Background = styled.div`
  background: url(${searchBackground}) no-repeat 90% 90%;
  height: 100vh;
  width: 100vw;
  background-size: 60rem;

  @media (max-width: 768px) {
    background-size: 30rem;
  }
`;

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 5rem;
    color: var(--color-title-dark);
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    display: flex;
    width: 100vw;
    padding: 3rem;
  }

  @media (max-width: 768px) {
    display: flex;
    width: 100vw;
    padding: 3rem;

    .form {
      width: 100%;
    }

    .search {
      width: 100%;
    }
  }
`;
