import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  border-radius: 1rem;
  background: var(--color-light);
  -webkit-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);

  & + div {
    margin-top: 3rem;
  }

  span {
    font-size: 1.8rem;
    font-family: Poppins;
    font-weight: 600;
    color: var(--color-title-dark);
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }

  footer {
    display: flex;
    flex-direction: row;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-secondary);
      height: 5.6rem;
      border-radius: 0.5rem;
      border: 0;
      padding: 0 2rem;
      color: var(--color-light);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.1, '#4361EE')};
      }

      & + button {
        margin-left: 2rem;
      }
    }

    .button-outline {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0.3rem solid var(--color-secondary);
      height: 5.6rem;
      width: 6rem;
      padding: 0;
      border-radius: 0.5rem;
      color: var(--color-secondary);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.7s;
      transition: color 0.7s;

      &:hover {
        background: ${shade(0.1, '#4361EE')};
        color: var(--color-light);
      }
    }
  }
`;
