import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface SearchBarProps {
  hasError?: boolean;
}

export const Container = styled.div<SearchBarProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--color-light);

  margin-top: 1rem;
  width: 92rem;

  -webkit-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  box-shadow: 0.4rem 0.4rem 3.8rem 0.4rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;

  padding: 0 0 0 2.2rem;

  input {
    flex: 1;
    height: 7rem;
    border: 0;
    border: 0.2rem solid #fff;
    color: var(--color-text-base);
    border-right: 0;
    background-color: transparent;

    ${props =>
      props.hasError &&
      css`
        border-color: var(--color-error);
      `}

    &::placeholder {
      color: var(--color-text-in-gray);
    }

    @media (max-width: 768px) {
      border: 0;
    }
  }

  svg {
    margin-right: 1.2rem;
  }

  button {
    width: 21rem;
    height: 7rem;
    background: var(--color-primary-light);
    border-radius: 0 0.5rem 0.5rem 0;
    border: 0;
    color: var(--color-text-light);
    font-weight: bold;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#D12D45')};
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 1rem;
    margin-top: 2rem;

    input {
      height: 4.8rem;
    }

    svg {
      margin-right: 1rem;
    }

    button {
      display: none;
    }
  }
`;
