import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 112rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media (min-width: 769px) and (max-width: 1120px) {
    padding: 3rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 2rem 2rem 2rem;
    width: 100vw;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50rem;
  background: var(--color-light);
  padding: 4rem;

  border-radius: 1rem;
  -webkit-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);
  box-shadow: 0.6rem 0.6rem 4rem 0.6rem rgba(0, 0, 0, 0.25);

  form {
    fieldset {
      border: none;
      display: flex;
      flex-direction: column;

      legend {
        font-family: Poppins;
        font-weight: 600;
        font-size: 2.4rem;
        color: var(--color-dark);
      }

      hr {
        margin: 0.5rem 0 3rem;
        border: 0.1rem solid var(--color-gray);
      }

      .form-row {
        display: flex;
        flex-direction: row;
        margin: 0 -1.5rem;

        & + .form-row {
          margin-top: 3rem;
        }

        > div {
          margin-top: 0;

          & {
            margin: 0 1.5rem;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-primary-light);
      height: 5rem;
      min-width: 18rem;
      border-radius: 0.5rem;
      border: 0;
      padding: 0 2rem;
      color: var(--color-light);
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: none;
      margin-top: 1.6rem;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.1, '#D12D45')};
      }

      & + button {
        margin-left: 2rem;
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    padding: 2rem;

    form {
      display: flex;
      flex-direction: column !important;

      fieldset {
        hr {
          margin: 1rem 0;
        }

        .form-row {
          display: flex;
          flex-direction: column !important;
          margin: 0;
          width: 100%;

          & + .form-row {
            margin: 0;
          }

          > div {
            width: 100%;
            margin: 1.2rem 0;
          }
        }
      }
    }

    footer {
      margin-top: 0;

      button {
        width: 100%;
      }
    }
  }
`;
