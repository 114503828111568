import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Background, Container } from './styles';

import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import SearchBar from '../../components/SearchBar';

interface SearchFormData {
  terms: string;
}

const Dashboard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = useCallback(
    (data: SearchFormData) => {
      history.push(`search?terms=${data.terms}`);
    },
    [history],
  );

  return (
    <div>
      <Background>
        <Header />
        <PageHeader title="Faça buscas nos pareceres do MPC" />
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit} className="form">
            <SearchBar
              name="terms"
              placeholder="Digite os termos da busca"
              icon={FiSearch}
              className="search"
            />
          </Form>
        </Container>
      </Background>
    </div>
  );
};

export default Dashboard;
